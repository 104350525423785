import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';



const StyledAboutSection = styled.section`
  max-width: 1000px;

  .inner {
    display: inline;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

//////////////////////////////////

const StyledTextCollab = styled.div`
  ul.collab-list {
    display: block;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 30px;
      font-size: 15;

      &:before {
        content: '▹';
        position: absolute;
        left: -14px;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 22px;
      }
    }
  }
`;

const StyledTextPublications = styled.div`
  ol.publications-list {
    display: block;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: number;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-size: 15;

    }
  }
`;

const StyledProject = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }



  .project-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

    @media (max-width: 768px) {
      color: var(--white);
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--lightest-slate);
    font-size: var(--fz-lg);

    @media (max-width: 768px) {
      padding: 20px 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 20px 5px 0;
      color: var(--lightest-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
      opacity: 0.25;
    }

    a {
      width: 100%;
      background-color: var(--white);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: whitescale(10%) contrast(1) brightness(90%);

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        filter: grayscale(100%) contrast(1) brightness(80%);
      }
    }
  }
`;

const Featured = () => {
  const data = useStaticQuery(graphql`
    query {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/featured/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  fluid(maxWidth: 700, traceSVG: { color: "#64ffda" }) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              tech
              github
              external
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);

  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Publications and Preprints
      </h2>
      <p> <br /><br /></p> 
      <h2><u>&#8362;   &nbsp;&nbsp; Featured Publications   &nbsp;&nbsp;&#8362; </u></h2>
      <p> <br /></p> 
      <div>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, github, cover } = frontmatter;

            return (
              <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                <div className="project-content">
                  <p className="project-overline"></p>
                  <h3 className="project-title">{title}</h3>
                  <div className="project-description" dangerouslySetInnerHTML={{ __html: html }} />

                  {tech.length && (
                    <ul className="project-tech-list">
                      {tech.map((tech, i) => (
                        <li key={i}>{tech}</li>
                      ))}
                    </ul>
                  )}

                  <div className="project-links">
                    {github && (
                      <a href={github} aria-label="GitHub Link">
                        <Icon name="GitHub" />
                      </a>
                    )}
                    {external && (
                      <a href={external} aria-label="External Link" className="external">
                        <Icon name="External" />
                      </a>
                    )}
                  </div>
                </div>

                <div className="project-image">
                  <a href={external ? external : github ? github : '#'}>
                    <Img fluid={cover.childImageSharp.fluid} alt={title} className="img" />
                  </a>
                </div>
              </StyledProject>
            );
          }
          )}
      </div>
      <p> <br /><br /><br /><br /><br /><br /></p> 
      <h2><u>&#8362;   &nbsp;&nbsp; Full list of Publications and Preprints    &nbsp;&nbsp; &#8362;</u></h2>
      <StyledAboutSection>
      <div className="inner"> 
      <p>&diams; <b>The Impact of Initialization on LoRA Finetuning Dynamics</b> (2024, submitted). <i>Soufiane Hayou, Nikhil Ghosh, Bin Yu.</i> <a id="link" href="https://arxiv.org/abs/2406.08447">Link</a></p>
      <p>&diams; <b>LoRA+: Efficient Low Rank Adaptation of Large Models</b> ICML 2024. <i>Soufiane Hayou, Nikhil Ghosh, Bin Yu.</i> <a id="link" href="https://arxiv.org/abs/2402.12354">Paper</a>, <a id="link" href="https://soufianehayou.substack.com/p/lora-efficient-low-rank-adaptation">Blog</a></p>
      <p>&diams; <b>Commutative Width and Depth Scaling in Deep Neural Networks</b> Accepted at JMLR, 2024. <i>Soufiane Hayou.</i> <a id="link" href="https://arxiv.org/abs/2310.01683">Link</a></p>
      <p>&diams; <b>How Bad is Training on Synthetic Data? A Statistical Analysis of Language Model Collapse. </b> COLM 2024. <i>Mohamed El Amine Seddik, Suei-Wen Chen, Soufiane Hayou, Pierre Youssef, Merouane Debbah.</i> <a id="link" href="https://arxiv.org/abs/2404.05090">Link</a></p>
      <p>&diams; <b>Tensor Programs VI: Feature Learning in Infinite Depth Neural Networks. </b> ICLR 2024. <i>Greg Yang, Dingli Yu, Chen Zhu, Soufiane Hayou.</i> <a id="link" href="https://arxiv.org/abs/2310.02244">Link</a></p>
      <p>&diams; <b>Leave-one-out Distinguishability in Machine Learning. </b> ICLR 2024. <i>Jiayuan Ye, Anastasia Borovykh, Soufiane Hayou, Reza Shokri.</i> <a id="link" href="https://arxiv.org/abs/2309.17310">Link</a></p>
      <p>&diams; <b>Width and Depth Commute in Residual Networks</b>. ICML 2023. <i>Soufiane Hayou, Greg Yang.</i> <a id="link" href="https://arxiv.org/abs/2302.00453">Link</a></p>
      <p>&diams; <b>Data Pruning and Neural Scaling Laws: Fundamental Limitations of Score-based Algorithms</b>. Transactions on Machine Learning Research (2023). <i>Fadhel Ayed, Soufiane Hayou.</i> <a id="link" href="https://arxiv.org/abs/2302.06960">Link</a></p>
      <p>&diams; <b>On the infinite-depth limit of finite-width neural networks</b>. Transactions on Machine Learning Research (2022). <i>Soufiane Hayou.</i> <a id="link" href="https://arxiv.org/abs/2210.00688">Link</a></p>
      <p>&diams; <b>Feature Learning and Signal Propagation in Deep Neural Networks</b>. ICML 2022. <i> Yizhang Lou, Chris Mingard, Yoonsoo Nam, Soufiane Hayou.</i> <a id="link" href="https://arxiv.org/abs/2110.11749">Link</a></p>
      <p>&diams; <b>From Optimization Dynamics to Generalization Bounds via Lojasiewicz Gradient Inequality</b>. Transactions on Machine Learning Research (2022). <i> Fusheng Liu, Haizhao Yang, Soufiane Hayou,  Qianxiao Li.</i> <a id="link" href="https://arxiv.org/abs/2202.10670"> Link</a></p>
      <p>&diams; <b>The curse of (non) convexity: The case of an Optimization-Inspired Data Pruning algorithm</b>. NeurIPS ICBINB Workshop 2022. <i> Fadhel Ayed, Soufiane Hayou.</i> <a id="link" href="https://openreview.net/pdf?id=5DZBAWOlit">Link</a></p>
      <p>&diams; <b>Regularization in ResNet with Stochastic Depth</b>. NeurIPS 2021. <i> Soufiane Hayou, Fadhel Ayed.</i> <a id="link" href="https://arxiv.org/abs/2106.03091">Link</a></p>
      <p>&diams; <b>The Curse of Depth in Kernel Regime</b>. Neurips 2021 ICBINB workshop (Spotlight). Proceedings of Machine Learning Research. <i>Soufiane Hayou, Arnaud Doucet, Judith Rousseau.</i><a href="https://proceedings.mlr.press/v163/hayou22a">Link.</a> </p>
      <p>&diams; <b>Probabilistic fine-tuning of pruning masks and PAC-Bayes self-bounded learning</b> (2021, ArXiv).<i> Soufiane Hayou, Bobby He, Gintare Karolina Dziugaite. </i><a href="https://arxiv.org/abs/2110.11804">Link.</a></p>
      <p>&diams; <b>Stochastic Pruning: Fine-Tuning, and PAC-Bayes bound optimization</b>. NeurIPS 2021 Bayesian Deep Learning Workshop. <i> Soufiane Hayou, Bobby He, Gintare Karolina Dziugaite. </i> <a href="http://bayesiandeeplearning.org/2021/papers/68.pdf">Link.</a></p>
      <p>&diams; <b>Robust Pruning at Initialization</b>. ICLR 2021.<i> Soufiane Hayou, Jean-Francois Ton, Arnaud Doucet, Yee Whye Teh.</i> <a href="https://arxiv.org/abs/2002.08797">Link.</a></p>
      <p>&diams; <b>Stable ResNet</b>. AISTATS 2021 (Oral presentation).<i>Soufiane Hayou, Eugenio Clerico, Bobby He, George Deligiannidis, Arnaud Doucet, Judith Rousseau.</i> <a href="https://arxiv.org/abs/2010.12859">Link.</a></p>
      <p>&diams; <b>On the Impact of the Activation function on Deep Neural Networks Training</b>. ICML 2019. <i>Soufiane Hayou, Arnaud Doucet, Judith Rousseau.</i> <a href="https://arxiv.org/abs/1902.06853">Link.</a></p>
      <p>&diams; <b>Mean-field Behaviour of Neural Tangent Kernel for Deep Neural Networks</b> (2020, submitted). <i>Soufiane Hayou, Arnaud Doucet, Judith Rousseau</i>. <a href="https://arxiv.org/abs/1905.13654">Link.</a></p>
      <p>&diams; <b>On the Overestimation of the Largest Eigenvalue of a Covariance Matrix</b>. (2017, Bloomberg). <i>Soufiane Hayou</i></p>
      <p>&diams; <b>Cleaning the Correlation Matrix with a Denoising AutoEncoder</b>. (2017, Bloomberg). <i>Soufiane Hayou</i></p>
      </div>
      </StyledAboutSection>
  
      <StyledTextCollab>


      </StyledTextCollab>

    </section>
  );
};

export default Featured;
